@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
  --layer-00: rgb(18, 18, 18);
  --layer-01: rgb(30, 30, 30);
  --layer-02: rgb(35, 35, 35);
  --layer-03: rgb(37, 37, 37);
  --layer-04: rgb(39, 39, 39);
  --layer-06: rgb(44, 44, 44);
  --layer-08: rgb(46, 46, 46);
  --layer-12: rgb(51, 51, 51);
  --layer-16: rgb(54, 54, 54);
  --layer-24: rgb(56, 56, 56);

  --black: #000000;
  --layer: #101010;
  --white: #FFFFFF;
  --tekhelet: #3D348B;
  --medium-slate-blue: #7678ED;
  --air-force-blue: #6C8EAD;
  --vermilion: #FF3C38;
  --columbia-blue: #BFDBF7;
  --lavender: #E1E5F2;
  --tropical-indigo: #8884FF;
  --thistle: #D7BCE8;
  --turquoise: #41EAD4;
  --imperial-red: #F71735;
  --penn-red: #92140C;
  --orange-peel: #FF9F1C;
  --teal: #1F7A8C;
  --gray1: #E2E2E2;
  --gray2: #AAAAAA;
  --jet: #353238;
  --silver: #C1B4AE;
  --ultra-violet: #4F4789;
  --umber: #6F5E53;
  --thistle: #DBC2CF;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--black);
  font-family: 'JetBrains Mono', monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--layer-04);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--layer-24);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--medium-slate-blue);
}