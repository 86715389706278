.App {
  width: 50%;
  min-height: 100vh;
  margin: 0 auto;
  color: var(--gray1);
  display: flex;
  flex-direction: column;
  padding: 3% 0;
  row-gap: 30px;
}

@media only screen and (max-width: 1280px) {
  .App {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .App {
    width: 90%;
  }
}